import React, { useContext, useEffect, useRef, useState } from "react";
import { Nav, Tab, Collapse, Button } from "react-bootstrap";
import { Link, navigate } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import ProfileSidebar from "../components/ProfileSidebar";
import network from "../../constants/Network";
import GlobalContext from "../context/GlobalContext";
import axios from "axios";

const UserQualification = ({ location }) => {

  const params = new URLSearchParams(location.search);
  const isMountedRef = useRef(false);

  const [user, setUser] = useState('');
  const gContext = useContext(GlobalContext);
  const userId = gContext?.user?.id || '';
  const { logedInUser } = useContext(GlobalContext);
  const [logedInUserId, setLogedInUserId] = useState('');

  const imageBaseUrl = `${network.serverip}/images/`;

  const [collapsible, setCollapsible] = useState('');

  const documentIconMap = {
    resume: 'fas fa-file-alt',
    aadhaar: 'fas fa-id-card',
    other_address_proof: 'fas fa-file',
  };

  const handleWordChange = (word) => {
    return word
      .replace(/_/g, ' ')
      .toUpperCase();
  };

  const handleFileDownload = async (fileName, name) => {
    try {
      // Define the document URL
      const documentUrl = `${network.serverip}/storage/${fileName}`;
      console.log('Downloading from URL:', documentUrl);

      // Fetch the document from the server using Axios
      const response = await axios.get(documentUrl, {
        responseType: 'blob' // Important: This ensures that the response is treated as a Blob
      });

      // Check if the response is successful
      if (response.status !== 200) {
        throw new Error('Failed to download document');
      }

      // Convert the response data into a Blob URL
      const fileUrl = window.URL.createObjectURL(response.data);

      // Create an anchor element to trigger the download
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = name; // Set the file name

      // Programmatically trigger a click event to download the file
      document.body.appendChild(a);
      a.click();

      // Cleanup: Remove the anchor and revoke the Blob URL
      a.remove();
      window.URL.revokeObjectURL(fileUrl);

      console.log(`Download complete: ${name}`);

      // Optionally, show a notification after the download completes
      alert(`The file "${name}" has been downloaded successfully.`);

    } catch (error) {
      console.error('Error downloading the document:', error);

      // Optional: Show a notification in case of failure
      alert(`Failed to download the file "${name}". Please try again.`);
    }
  };

  useEffect(() => {
    if (logedInUser && logedInUser.id) {
      setLogedInUserId(logedInUser.id)
    }
  }, [logedInUser]);

  const fetchUserDetails = async () => {
    try {
      const response = await axios.post(`${network.serverip}/api/profile-details-for-web`, { userId: userId });

      if (response.data.success && isMountedRef.current) {
        setUser(response.data.userData);
        console.log('This is the fetched user details:', response.data.userData);
      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setUser('');
        }
        console.error('Failed to fetch user details');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setUser('');
      }
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    if (!userId) {
      console.log('Waiting for the candidate id');
      return;
    }

    isMountedRef.current = true;

    fetchUserDetails();

    return () => {
      isMountedRef.current = false;
    };
  }, [userId]);

  const styles = UserQualificationStyling();

  return (
    <>
      <PageWrapper headerConfig={{ button: "profile" }}>
        <div className="bg-default-2 pt-32 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            {/* <!-- back Button --> */}
            <div className="row justify-content-center">
              <div className="col-10 dark-mode-texts">
                <div className="mb-9">
                  <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => {
                    if (window.history.length > 1) {
                      navigate(-1);
                    } else {
                      navigate('/');
                    }
                  }} className="d-flex align-items-center ml-4">
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">

              <div className="col-10">
                <div className="bg-white rounded-4 shadow-9">
                  <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-5">
                    <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                      Qualification
                    </h4>
                  </div>

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <Link
                      to="/user-education"
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-5 text-black-2 font-weight-semibold mb-0">
                        Education
                      </h4>
                      <i className="fas fa-pencil-alt text-success font-size-5"></i>
                    </Link>

                    <div>
                      {user && user.qualification && user.qualification.length > 0 ? (
                        user.qualification.map((qual, index) => {
                          const fromDate = qual.from_date ? new Date(qual.from_date) : null;
                          const toDate = qual.to_date ? new Date(qual.to_date) : null;

                          const formattedFromDate = fromDate
                            ? fromDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })
                            : 'Start date not available';

                          const formattedToDate = toDate
                            ? toDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })
                            : 'End date not available';

                          return (
                            <div className="w-100 border-top" key={index}>
                              <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap flex-sm-nowrap">
                                <div className="d-flex align-items-start mt-0" style={{ width: '3rem' }}>
                                  <i className="fas fa-graduation-cap font-size-4 text-success"></i>
                                </div>
                                <div className="w-100">
                                  <p className="mb-0 font-size-4 text-black-2 font-weight-semibold">
                                    {qual?.qualification_name?.title}
                                  </p>
                                  <p className="font-size-4 text-default-color line-height-2">
                                    {qual?.field_of_study?.title} {`, ${qual?.institution}`}
                                  </p>
                                  <div className="d-flex align-items-center justify-content-md-between flex-wrap">
                                    <span className="font-size-4 text-gray mr-5">
                                      {`${formattedFromDate} - ${formattedToDate}`}
                                    </span>
                                    <span className="font-size-3 text-gray">
                                      <i className="fas fa-map-marker-alt mr-4"></i>
                                      {qual?.location}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p>No qualification available.</p>
                      )}
                    </div>
                  </div>

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                  <Link
                      to="/user-skill"
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-5 text-black-2 font-weight-semibold mb-0">
                        Skill
                      </h4>
                      <i className="fas fa-pencil-alt text-success font-size-5"></i>
                    </Link>

                    <div>
                      {user && user.user_skill && user.user_skill.length > 0 ? (
                        user.user_skill.map((skill, index) =>
                        (
                          <div className="w-100 border-top" key={index}>
                            <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap flex-sm-nowrap">
                              <div className="d-flex align-items-start mt-0" style={{ width: '3rem' }}>
                                <i className="fas fa-wrench font-size-4 text-success"></i>
                              </div>
                              <div className="w-100">
                                <p className="mb-0 font-size-4 text-black-2 font-weight-semibold">
                                  {skill?.skill?.title || 'N/A'}
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                        )
                      ) : (
                        <p>No skills available.</p>
                      )}
                    </div>
                  </div>

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <Link
                      to="/user-experience"
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-5 text-black-2 font-weight-semibold mb-0">
                        Experience
                      </h4>
                      <i className="fas fa-pencil-alt text-success font-size-5"></i>
                    </Link>

                    <div>
                      {user && user.experience && user.experience.length > 0 ? (
                        user.experience.map((exp, index) => {
                          const fromDate = exp.from_date ? new Date(exp.from_date) : null;
                          const toDate = exp.currently_work_here
                            ? 'Present'
                            : exp.to_date
                              ? new Date(exp.to_date)
                              : null;

                          const formattedFromDate = fromDate
                            ? fromDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })
                            : 'Start date not available';
                          const formattedToDate =
                            toDate === 'Present'
                              ? 'Present'
                              : toDate
                                ? toDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })
                                : 'End date not available';

                          const monthsOfExperience =
                            fromDate && toDate !== 'Present' && toDate
                              ? Math.floor((toDate - fromDate) / (1000 * 60 * 60 * 24 * 30))
                              : fromDate && toDate === 'Present'
                                ? Math.floor((new Date() - fromDate) / (1000 * 60 * 60 * 24 * 30))
                                : null;

                          const yearsOfExperience = monthsOfExperience ? (monthsOfExperience / 12).toFixed(1) : null;

                          const experienceInYears = yearsOfExperience
                            ? `${yearsOfExperience} year(s)`
                            : 'Experience not available';

                          return (
                            <div className="w-100 border-top" key={index}>
                              <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap flex-sm-nowrap">
                                <div className="d-flex align-items-start mt-0" style={{ width: '3rem' }}>
                                  <i className="fas fa-briefcase font-size-4 text-success"></i>
                                </div>
                                <div className="w-100">
                                  <p className="mb-0 font-size-4 text-black-2 font-weight-semibold">
                                    {exp?.job_title}
                                  </p>
                                  <p className="font-size-4 text-default-color line-height-2">
                                    {exp?.institution_name}
                                  </p>
                                  <div className="d-flex align-items-center justify-content-md-between flex-wrap">
                                    <span className="font-size-4 text-gray mr-5">
                                      {`${formattedFromDate} - ${formattedToDate} (${experienceInYears})`}
                                    </span>
                                    <span className="font-size-3 text-gray">
                                      <i className="fas fa-map-marker-alt mr-4"></i>
                                      {exp?.location}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p>No experience available.</p>
                      )}
                    </div>
                  </div>

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <Link
                      to="/user-procedure"
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-5 text-black-2 font-weight-semibold mb-0">
                        Procedure
                      </h4>
                      <i className="fas fa-pencil-alt text-success font-size-5"></i>
                    </Link>

                    <div>
                      {user && user.procedure && user.procedure.length > 0 ? (
                        user.procedure.map((proc, index) => {

                          return (
                            <div className="w-100 border-top" key={index}>
                              <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap flex-sm-nowrap">
                                <div className="d-flex align-items-start mt-0" style={{ width: '3rem' }}>
                                  <i className="fas fa-hospital-alt font-size-4 text-success"></i>
                                </div>
                                <div className="w-100">
                                  <p className="mb-0 font-size-4 text-black-2 font-weight-semibold">
                                    {proc?.title}
                                  </p>
                                  <p className="font-size-4 text-default-color line-height-2">
                                    {`Number of times performed: ${proc?.procedure_count}`}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p>No procedure available.</p>
                      )}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default UserQualification;

function UserQualificationStyling() {
  const styles = {
    collapsibleHeadingButton:
    {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    downloadButton: {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
    },
  };

  return styles;
}

